<template>
<h1>Mes réservations</h1>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <div class="form-group">
                        <select @change="onChange($event)" class="form-control">
                            <option v-for="etablissement in etablissements" :key="etablissement.id" :value="etablissement.uuid">
                                {{ etablissement.titre }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="ibox-content">
                    
                    <div class="row">
                        <DateSelector v-if="dataSource.id"
                            :offre="dataSource"
                            :height="600"
                            :editing="editing"
                        >
                        </DateSelector>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

import DateSelector from "@/components/DateSelector";

export default {
  name: 'Reservations',
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      etablissement: {},
      reservations: [],
      editing: {
        allowAdding: false,
        allowDeleting: false,
        allowDragging: false,
        allowResizing: false,
        allowTimeZoneEditing: false,
        allowUpdating: false
      },
      masks: {
        weekdays: 'WWW',
      },
      attributes: [
        {
          key: 1,
          customData: {
            title: 'Lunch with mom.',
            class: 'bg-red-600 text-white',
          },
          dates: new Date(year, month, 1),
        },
        {
          key: 2,
          customData: {
            title: 'Take Noah to basketball practice',
            class: 'bg-blue-500 text-white',
          },
          dates: new Date(year, month, 2),
        },
        {
          key: 3,
          customData: {
            title: "Noah's basketball game.",
            class: 'bg-blue-500 text-white',
          },
          dates: new Date(year, month, 5),
        },
        {
          key: 4,
          customData: {
            title: 'Take car to the shop',
            class: 'bg-indigo-500 text-white',
          },
          dates: new Date(year, month, 5),
        },
        {
          key: 4,
          customData: {
            title: 'Meeting with new client.',
            class: 'bg-teal-500 text-white',
          },
          dates: new Date(year, month, 7),
        },
        {
          key: 5,
          customData: {
            title: "Mia's gymnastics practice.",
            class: 'bg-pink-500 text-white',
          },
          dates: new Date(year, month, 11),
        },
        {
          key: 6,
          customData: {
            title: 'Cookout with friends.',
            class: 'bg-orange-500 text-white',
          },
          dates: { months: 5, ordinalWeekdays: { 2: 1 } },
        },
        {
          key: 7,
          customData: {
            title: "Mia's gymnastics recital.",
            class: 'bg-pink-500 text-white',
          },
          dates: new Date(year, month, 22),
        },
        {
          key: 8,
          customData: {
            title: 'Visit great grandma.',
            class: 'bg-red-600 text-white',
          },
          dates: new Date(year, month, 25),
        },
      ]
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData',
    etablissements: function(newValue) {
      if (newValue && newValue[0]) {
        this.fetchReservations(newValue[0].uuid);
        this.etablissement = newValue[0];
      }
    }
  },
  methods: {
    fetchData () {
      this.$store.dispatch('getEtablissements').then(() =>  {
        console.log(this.etablissements)
      });
    },
    fetchReservations (uuid) {
      axios
        .all([
          axios.get(process.env.VUE_APP_API + "/producteur/etablissement/" + uuid),
          axios.get(process.env.VUE_APP_API + "/web/etablissement/reservations/" + uuid)
        ])
        .then(
          axios.spread((...responses) => {
            this.etablissement = responses[0].data;
            this.reservations = responses[1].data;
            // loading.close();
          })
        )
        .catch(() => {
            console.log("ERROR");
        });
    },
    onChange (e) {
      this.fetchReservations(e.target.value);
    }
  },
  computed: {
    etablissements () {
      return this.$store.state.etablissements.etablissements
    },
    dataSource() {
      return {
        id: this.etablissement.id,
        titre: this.etablissement.titre,
        duree: 0,
        dates: this.reservations.map(d => {
          return {
            date: d.date_start,
            endDate: d.date_end
          }
        })
      }
    }
  },
  components: {
    DateSelector
  },
};
</script>